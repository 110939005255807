import axios from "axios";
import { useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import * as React from "react";
import { Capacitor } from "@capacitor/core";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Link,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
  IconButton,
} from "@mui/material";
import "../../App.css";
import { CalendarLinksModal } from "../../components/CalendarLinksModal";
import EventIcon from "@mui/icons-material/Event";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AddIcon from "@mui/icons-material/Add";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MapIcon from "@mui/icons-material/Map";
import EventPlaceholderImage from "../../assets/event-placeholder.jpg";
import { EventInvitationForm } from "../EventInvitationForm";
import { SERVER_URL, timeConverter } from "../../utils";
import { useAppContext } from "../../AppContext";
import { UpsertCalendarEvent, DeleteCalendarEvent } from "../../hooks/calendar";
import "../../App.css";
import EntertainmentIcon from "../../assets/event-categories/entertainment.png";
import FoodAndDiningIcon from "../../assets/event-categories/food-and-dining.png";
import HappyHourIcon from "../../assets/event-categories/happy-hour.png";
import SportsAndActivitiesIcon from "../../assets/event-categories/sports.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colors } from "../theme/colors";

interface CardModalProps {
  info: any;
  open: boolean;
  setOpen: (p: boolean) => void;
  idx: number;
  setIdx: (p: number) => void;
  markers: mapboxgl.Marker[];
  eventsCount: number;
}

export const EventsCard = ({
  info,
  setOpen,
  idx,
  setIdx,
  open,
  eventsCount,
}: CardModalProps) => {
  const [savedItem, setSavedItem] = useState(info.IsSaved);
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const [showCalendarAdd, setShowCalendarAdd] = useState(false);
  const { safearea, ratingRequestMetric, setRatingRequestMetric } =
    useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    setSavedItem(info.IsSaved === true);
  }, [info]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    setEventFormOpen(false);
  };

  const handleNameClick = () => {
    const newMapUrl = `https://www.google.com/maps/search/${
      info.LocationName
    }${" "}${info.Address}${" "}${formatAddress(info.City, info.State)}`;
    window.open(newMapUrl);
  };

  const formatAddress = (city, state) => {
    let address2array = [];
    address2array.push(city, state);
    return address2array.join(", ");
  };

  const Puller = () => {
    return (
      <Box
        sx={{
          width: 100,
          height: 6,
          borderRadius: 3,
          position: "absolute",
          top: 8,
          left: "calc(50% - 50px)",
          backgroundColor: open ? "lightgrey" : "grey",
        }}
        onClick={toggleDrawer(false)}
      />
    );
  };

  let currentView;

  if (eventFormOpen) {
    currentView = (
      <EventInvitationForm info={info} close={() => setEventFormOpen(false)} />
    );
  } else {
    currentView = (
      <>
        <CardMedia
          sx={{ height: 150 }}
          image={info.ImageUrl || EventPlaceholderImage}
        />

        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            variant="text"
            onClick={() => {
              if (!userIsLoggedIn) {
                navigate(`/app/auth/login?redirect=/app/events`);
                return;
              }
              setEventFormOpen(true);
            }}
            sx={{
              position: "absolute",
              width: "100%",
              left: "0",
              top: "150px",
              padding: "0",
            }}
          >
            <Stack direction="row" className="invite-friends-inner-button">
              <AddIcon className="add-icon-button" />
              <div
                style={{
                  display: "inline-block",
                  color: "white",
                }}
              >
                Invite Friends
              </div>
            </Stack>
          </Button>
        </Grid> */}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="event-card-name-grid"
          sx={{
            marginBlockStart: "10px",
          }}
        >
          <Link target="_blank" href={info.Url} sx={{ width: "100%" }}>
            <Typography gutterBottom variant="h5" className="event-card-name">
              {info.EventName}
            </Typography>
          </Link>

          <Stack direction="column" sx={{ gap: "4px", width: "100%" }}>
            {info?.Categories?.length > 0 && (
              <Stack direction="row">
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "350px",
                    fontSize: "16px",
                  }}
                >
                  <img
                    alt="cateogires"
                    style={{
                      height: "25px",
                      margin: "0 5px -5px 0",
                    }}
                    src={
                      {
                        Entertainment: EntertainmentIcon,
                        "Happy Hour": HappyHourIcon,
                        "Food & Dining": FoodAndDiningIcon,
                        Activities: SportsAndActivitiesIcon,
                      }[info.Categories[0]]
                    }
                  ></img>
                  {info.Categories[0]}
                </Typography>
              </Stack>
            )}
            <Stack direction="column">
              <Stack direction="row" sx={{ gap: "4px" }}>
                <ScheduleIcon />
                <Typography sx={{ fontSize: "16px" }}>
                  {timeConverter(info.StartingAt, info.EndingAt)}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row">
              <StorefrontIcon className="store-front-icon " />
              <Typography
                sx={{
                  maxWidth: "275px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {info.LocationName}
              </Typography>
            </Stack>
            <Stack direction="row">
              <MapIcon
                style={{
                  color: "#233162",
                  height: "25px",
                  margin: "0 5px -4px 0",
                }}
              />
              <Link target="_blank" onClick={handleNameClick}>
                <Typography
                  sx={{
                    maxWidth: "275px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "16px",
                  }}
                >
                  {info.Address} {formatAddress(info.City, info.State)}
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Grid>

        <Stack
          direction="row"
          sx={{
            margin: "0 auto",
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            gap: "36px",
          }}
        >
          <IconButton
            onClick={e => {
              setRatingRequestMetric({
                ...ratingRequestMetric,
                eventsViewCount: ratingRequestMetric.eventsViewCount + 1,
              });
              if (idx >= eventsCount) setIdx(0);
              else setIdx(idx === 0 ? eventsCount - 1 : idx - 1);
            }}
          >
            <ArrowBackIcon style={{ color: colors.blue }} />
          </IconButton>
          <Button
            sx={{
              color: colors.blue,
              background: "#EEE",
              overflow: "hidden",
            }}
            onClick={toggleDrawer(false)}
          >
            Close
          </Button>
          <IconButton
            sx={{ overflow: "hidden" }}
            onClick={e => {
              setRatingRequestMetric({
                ...ratingRequestMetric,
                eventsViewCount: ratingRequestMetric.eventsViewCount + 1,
              });
              if (idx >= eventsCount) setIdx(0);
              else setIdx(idx === eventsCount - 1 ? 0 : idx + 1);
            }}
          >
            <ArrowForwardIcon style={{ color: colors.blue }} />
          </IconButton>
        </Stack>

        {/* <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            justifyContent: "flex-end",
            marginLeft: "inherit",
            position: "absolute",
            right: 0,
            top: "150px",
          }}
        >
          {Capacitor.getPlatform() !== "ios" ? (
            <Button
              variant="text"
              sx={{
                position: "absolute",
                bottom: 0,
                right: "80px",
                background: "rgba(35, 49, 98, 0.9)",
                borderRadius: "5px 5px 0 0",
                border: "solid 1px darkgrey",
                borderBottom: 0,
                height: "40.5px",
                ":hover": {
                  background: "rgba(35, 49, 98, 1)",
                },
              }}
              onClick={() => {
                setShowCalendarAdd(true);
              }}
            >
              <EventIcon style={{ color: "white" }} />
            </Button>
          ) : (
            ""
          )}
          <Button
            variant="text"
            sx={{
              position: "absolute",
              bottom: 0,
              right: "10px",
              background: savedItem
                ? "rgba(239, 82, 71, 0.9)"
                : "rgba(35, 49, 98, 0.9)",
              borderRadius: "5px 5px 0 0",
              border: "solid 1px darkgrey",
              borderBottom: 0,
              height: "40.5px",
              ":hover": {
                background: savedItem
                  ? "rgba(239, 82, 71, 1)"
                  : "rgba(35, 49, 98, 1)",
              },
            }}
            onClick={() => {
              if (!userIsLoggedIn) {
                navigate(`/app/auth/login?redirect=/app/events`);
                return;
              }
              setRatingRequestMetric({
                ...ratingRequestMetric,
                eventsSavedCount: ratingRequestMetric.eventsSavedCount + 1,
              });
              axios
                .post(
                  savedItem
                    ? `${SERVER_URL}/api/event/unsave`
                    : `${SERVER_URL}/api/event/save`,
                  JSON.stringify({
                    event_id: info.EventId,
                  })
                )
                .then(response => {
                  setSavedItem(!savedItem);
                });
              if (savedItem) {
                DeleteCalendarEvent(info.EventId);
              } else {
                const endDate =
                  Date.parse(info.EndingAt) ||
                  Date.parse(info.StartingAt) + 3600000;
                UpsertCalendarEvent({
                  id: info.EventId,
                  title: info.EventName,
                  location: `${info.Address}, ${info.City} ${info.State}`,
                  notes: info.Description,
                  startDate: Date.parse(info.StartingAt),
                  endDate: endDate,
                });
              }
            }}
          >
            {savedItem ? (
              <BookmarkIcon style={{ color: "white" }} />
            ) : (
              <BookmarkBorderIcon style={{ color: "white" }} />
            )}
          </Button>
        </Grid> */}
      </>
    );
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      key={info.EventId}
      id={`listitem${info.EventId}`}
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      variant="persistent"
      PaperProps={{
        sx: {
          position: "absolute",
          bottom: `calc(15px + ${safearea?.bottom}px)`,
          borderRadius: "24px",
          margin: "8px",
          maxWidth: "400px",
          width: "calc(100% - 16px)",
          marginBlockEnd: "8px",
          backgroundColor: "rgba(255, 255, 255)",
          boxShadow: "0px -5px 15px -5px rgba(0,0,0,0.65)",
          WebkitBoxShadow: "0px -5px 15px -5px rgba(0,0,0,0.65)",
          MozBoxShadow: "0px -5px 15px -5px rgba(0,0,0,0.65)",
        },
      }}
    >
      <Paper elevation={3} sx={{ background: "none" }}>
        <Puller />

        <Box
          sx={{
            paddingBlockEnd: "24px",
            borderRadius: "24px",
            maxWidth: "400px",
            height: 375,
            marginBlockEnd: "4px",
          }}
        >
          {currentView}
        </Box>
        {/* <CalendarLinksModal
          open={info && showCalendarAdd}
          onClose={() => {
            setShowCalendarAdd(false);
          }}
          event={info}
        ></CalendarLinksModal> */}
      </Paper>
    </SwipeableDrawer>
  );
};
