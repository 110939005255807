import React, { FC } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export interface SocialLink {
  name: string;
  link: string;
  icon?: string;
}

export const socialLinks: SocialLink[] = [
  {
    name: "Instagram",
    link: "https://instagram.com/gyde.live?igshid=YTQwZjQ0NmI0OA==",
    icon: "instagram.svg",
  },
];

interface SocialLinkItemProps {
  item: SocialLink;
}

const SocialLinkItem: FC<SocialLinkItemProps> = ({ item }) => (
  <Box
    component="li"
    sx={{
      display: "inline-block",
      color: "#fbfbfb",
      mr: 0.5,
    }}
  >
    <Link
      target="_blank"
      sx={{
        lineHeight: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 36,
        height: 36,
        borderRadius: "50%",
        color: "inherit",
        "&:hover": {
          backgroundColor: "secondary.main",
        },
        "& img": {
          fill: "currentColor",
          width: 22,
          height: "auto",
        },
      }}
      href={item.link}
    >
      {/* eslint-disable-next-line */}
      <img src={item.icon} alt={item.name + "icon"} />
    </Link>
  </Box>
);

// default
const SocialLinks: FC = () => {
  return (
    <Box sx={{ ml: -1 }}>
      <Box
        component="ul"
        sx={{
          m: 0,
          p: 0,
          lineHeight: 0,
          borderRadius: 3,
          listStyle: "none",
        }}
      >
        {socialLinks.map(item => {
          return <SocialLinkItem key={item.name} item={item} />;
        })}
      </Box>
    </Box>
  );
};

export default SocialLinks;
