import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Londrina/LondrinaSolid-Black.ttf";
import "./fonts/Londrina/LondrinaSolid-Light.ttf";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { MyListPage } from "./pages/MyListPage";
import { EventMapPage } from "./pages/EventMapPage";
import { AccountPage } from "./pages/AccountPage";
import { AppProvider } from "./AppContext";
import { PrivacyPolicyTemplate } from "./components/PrivacyPolicyTemplate";

import Landing from "./components/landing/pages/index";
import { LandingPrivacyPolicy } from "./components/landing/pages/LandingPrivacyPolicy";
import { SignUpPage } from "./pages/SignUpPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ForgotPassword } from "./pages/ForgotPassword";
import { VerifyEmailPage } from "./pages/VerifyEmailPage";

import { Capacitor } from "@capacitor/core";
import { BusinessProvider } from "./pages/BusinessContext";

import { RestaurantsPage } from "./src-gql/pages/restaurants";
import { ApiProvider } from "./src-gql/components/context";
import { EventsPage } from "./src-gql/pages/events";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ApiProvider>
        <AppProvider>
          <Routes>
            <Route
              path="/"
              element={
                Capacitor.isNativePlatform() ? <EventMapPage /> : <Landing />
              }
            />
            {/* <Route
              path="/app"
              element={
                <BusinessProvider>
                  <RestaurantsPage />
                </BusinessProvider>
              }
            /> */}
            {/* <Route path="/app/auth/login" element={<LoginPage />} />
            <Route path="/app/auth/signup" element={<SignUpPage />} />
            <Route
              path="/app/auth/password-reset"
              element={<ResetPasswordPage />}
            />
            <Route
              path="/app/auth/forgot-password"
              element={<ForgotPassword />}
            />
            <Route path="/app/auth/verify-email" element={<VerifyEmailPage />} />
            <Route path="/app/mylist" element={<MyListPage />} /> */}
            {/* <Route
              path="/app/restaurants"
              element={
                <BusinessProvider>
                  <RestaurantsPage />
                </BusinessProvider>
              }
            /> */}
            <Route path="/app" element={<EventMapPage />} />
            {/* <Route path="/app/account" element={<AccountPage />} />
            <Route path="/privacy" element={<PrivacyPolicyTemplate />} />
            <Route path="/landing/privacy" element={<LandingPrivacyPolicy />} /> */}
          </Routes>
        </AppProvider>
      </ApiProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
