import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { MapBoxToken, MapStyle } from "../utils/tokens";
import styled from "styled-components";

import SelfMarker from "../assets/markers/self.png"
import { useSafearea } from "./page";

mapboxgl.accessToken = MapBoxToken;

const MapWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
`

export const Map = ({ currLoc, items, offset, withSelfMarker, flyToFirstMarker }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const { safearea } = useSafearea();
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (!mapContainer.current || !currLoc) return
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: MapStyle,
            center: [currLoc.longitude, currLoc.latitude],
            offset: offset || [0, -180],
            zoom: 11,
        });

        map.current.once("load", () => {
            setLoaded(true)
        });

        if (withSelfMarker) {
            const selfMarker = document.createElement("img");
            selfMarker.setAttribute("src", SelfMarker);
            selfMarker.setAttribute("class", "self-marker");
            new mapboxgl.Marker(selfMarker)
                .setLngLat([currLoc.longitude, currLoc.latitude])
                .addTo(map.current);
        }
    }, [mapContainer.current, currLoc])

    useEffect(() => {
        if (!loaded || !items || items.length === 0) return
        Array.from(document.querySelectorAll(".marker")).forEach(
            ele => {
                ele.remove();
            }
        );
        items.map((m, i) => {
            const img = document.createElement("img");
            img.setAttribute("class", `${m.className || ""} marker`);
            img.setAttribute("src", m.imageUrl);
            const marker = new mapboxgl.Marker(img)
                .setLngLat([m.longitude, m.latitude])
                .addTo(map.current);
            marker.getElement().addEventListener("click", e => {
                if (m.onClick) m.onClick(e, m, i)
            })
        });
        if (flyToFirstMarker) {
            map.current.flyTo({
                center: [items[0].longitude, items[0].latitude],
                offset: offset || [0, 0],
                zoom: 15,
            });
        }
    }, [items, loaded])
    return <MapWrapper ref={mapContainer} className="cmp-map" />
}