import axios from "axios";
import { SERVER_URL } from "../utils";

export const importContacts = contacts => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${SERVER_URL}/api/people/import-contacts`,
        JSON.stringify({
          people: contacts,
        })
      )
      .then(resolve)
      .catch(reject);
  });
};
