import styled from "styled-components";
import intl from "react-intl-universal";
import { useMutation } from "urql";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import {
  SaveBusinessMutation,
  UnsaveBusinessMutation,
} from "../../queries/business";
import { Carousel } from "../carousel";

import CallIcon from "@mui/icons-material/Call";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useSafearea } from "../page";
import { colors } from "../../utils/colors";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../AppContext";

import StarIcon from "@mui/icons-material/Star";
import MapIcon from "@mui/icons-material/Map";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import HeartIcon from "../../assets/icons/heart.svg";
import HeartEmptyIcon from "../../assets/icons/heart-empty.svg";

const HeartButton = styled.div`
  right: 0;
  width: 20px;
  height: 20px;
  margin: 0.5em;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  background-size: cover;
  transition: background 0.4s;
`;

const Category = styled.div`
  display: inline-block;
  background-color: lightgrey;
  color: ${colors.blue};
  border-radius: 100px;
  padding: 0.125em 0.5em;
  margin: 0.25em 0.25em;
  font-size: 16px;
  font-weight: 600;
  white-space: break-spaces;
`;

const Link = styled.a`
  color: #0072e5;
  text-decoration-color: #0072e5;
`;

export const NoResults = styled.div.attrs(({ $bottom }) => ({ $bottom }))`
  padding: 16px;
  background-color: white;
  width: 350px;
  border-radius: 12px;
  position: fixed;
  bottom: ${({ $bottom }) => $bottom + 58}px;
  left: 4px;
  text-align: center;
`;

export const BusinessCard = ({
  data,
  input,
  setInput,
  isSaved,
  setIsSaved,
}) => {
  const { safearea } = useSafearea();
  const navigate = useNavigate();
  const [, saveBusiness] = useMutation(SaveBusinessMutation);
  const [, unsaveBusiness] = useMutation(UnsaveBusinessMutation);
  const { userIsLoggedIn } = useAppContext();
  const business = data?.business;
  if (!business) return "";
  const { name, photos, url, categories, rating, price, location, phone } =
    business;
  const address = `${location.address_1}, ${location.city}, ${location.state} ${location.zip_code}`;

  const handleLinkClick = () => {
    const { latitude, longitude } = input.coordinates;
    const newMapUrl = `https://www.google.com/maps/dir/${latitude},${longitude}/${name} ${location.address_1}${location.address_2}`;
    window.open(newMapUrl);
  };
  // I'm copy pasting our existing business card into here with material ui components to save time on rewriting everything
  // we can rewrite this into styled components after we get it out
  return !data ? (
    <NoResults $bottom={safearea.bottom}>{intl.get("noResults")}</NoResults>
  ) : (
    <Card
      sx={{
        backgroundColor: "white",
        boxShadow: "0px 5px 5px 5px rgba(0, 0, 0, 0.1)",
        margin: "0 auto",
        width: "95%",
        maxWidth: "400px",
        left: "10px",
        position: "absolute",
        zIndex: 2,
        borderRadius: "12px",
        blockSize: "350px",
        bottom: "10px",
        height: "420px",
      }}
    >
      <Carousel photos={photos} />
      <CardContent align="initial" sx={{ marginBlockStart: "-10px" }}>
        <Grid item container>
          <Link target="_blank" href={url}>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </Typography>
          </Link>
        </Grid>
        <Stack direction="column" sx={{ gap: "2px" }}>
          <Stack direction="row" sx={{ alignItems: "flex-start" }}>
            <RestaurantMenuIcon
              sx={{ color: colors.orange, marginBlockStart: "4px" }}
            />

            <div>
              {categories.slice(0, 2).map((category, i) => {
                return <Category key={i}>{category}</Category>;
              })}
            </div>
          </Stack>
          <Stack direction="row" sx={{ gap: "24px" }}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "fit-content",
                marginBottom: "3px",
              }}
            >
              <StarIcon
                sx={{
                  background: "#EF5247",
                  color: "white",
                  fontSize: 16,
                  padding: "2px",
                  borderRadius: "5px",
                  marginRight: "8px",
                }}
              />
              <Typography variant="body1">{rating} Stars</Typography>
            </div>
            <Typography sx={{ fontWeight: 600 }}>{price}</Typography>
          </Stack>

          <Stack direction="row">
            <MapIcon
              style={{
                width: "20px",
                marginRight: "8px",
                marginBottom: "-8px",
                color: "#233162",
              }}
            ></MapIcon>
            <Link
              target="_blank"
              onClick={handleLinkClick}
              variant="body2"
              component="button"
              sx={{
                textAlign: "inherit",
                maxWidth: "calc(100% - 50px)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "16px",
              }}
            >
              {address}
            </Link>
          </Stack>
          <div style={{ display: "flex" }}>
            <CallIcon
              style={{
                width: "20px",
                marginRight: "4px",
                marginBottom: "-8px",
                color: "#233162",
              }}
            ></CallIcon>
            <Link
              href={`tel:${phone}`}
              variant="body2"
              component="button"
              sx={{ textAlign: "inherit" }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "16px" }}
              >
                {phone}
              </Typography>
            </Link>
          </div>
        </Stack>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "space-between",
          position: "absolute",
          bottom: 0,
          width: "-webkit-fill-available",
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            marginBlockEnd: "4px",
            alignItems: "flex-start",
            width: "-webkit-fill-available",
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.orange,
            }}
            variant="contained"
            onClick={() => {
              if (data?.total <= 1) return;
              let randCursor = -1;
              do randCursor = Math.floor(Math.random() * data?.total);
              while (input?.cursor === randCursor);
              setInput({ ...input, cursor: randCursor });
            }}
          >
            Spin
            <KeyboardArrowRightIcon />
          </Button>
          {/* <HeartButton
              src={isSaved && userIsLoggedIn ? HeartIcon : HeartEmptyIcon}
              onClick={() => {
                if (!userIsLoggedIn)
                  navigate("/app/auth/login?redirect=/app/restaurants");
                if (isSaved)
                  unsaveBusiness({ input: { id: business?.id } }).then(
                    ({ error }) => {
                      if (error) {
                        console.error(error);
                        return;
                      }
                      setIsSaved(false);
                    }
                  );
                else
                  saveBusiness({ input: { id: business?.id } }).then(
                    ({ error }) => {
                      if (error) {
                        console.error(error);
                        return;
                      }
                      setIsSaved(true);
                    }
                  );
              }}
            /> */}
        </Stack>
      </CardActions>
    </Card>
  );
};
