import { Box, Typography, Stack, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { SERVER_URL } from "../utils";
import { useNavigate } from "react-router-dom";
import party from "../assets/party.png";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../App.css";
import { SearchTable } from "./SearchTable";
import { EmptyContactsList } from "./Contacts";
import { useAppContext } from "../AppContext";

interface EventInvitationFormProps {
  info: any;
  close: () => void;
}

export const EventInvitationForm = ({
  info,
  close,
}: EventInvitationFormProps) => {
  const [selectedIds, setSelectedIds] = useState({});
  const [inviteSent, setInviteSent] = useState(false);
  const [contactList, setContactList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const navigate = useNavigate();
  // const { user } = useAppContext();

  useEffect(() => {
    axios
      .post(
        `${SERVER_URL}/api/event/list-contacts`,
        JSON.stringify({
          cursor: 0,
          limit: 300,
          event_id: info.EventId,
        })
      )
      .then(function (res) {
        const { data } = res;
        // if (data) {
        //   setContactList(data.filter(p => p.PersonId !== user.person_id));
        //   setInviteList(data.filter(p => p.InvitationStatus));
        // }
      });
  }, [info.EventId]);

  let currentView;

  if (inviteSent) {
    currentView = (
      <>
        <Stack
          className="event-form-outer-stack"
          direction="column"
          sx={{ alignItems: "center" }}
        >
          <img src={party} alt="" width={64} height={64} />
          <div
            style={{
              padding: "12px 0",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "700", marginTop: "12px" }}
            >
              Let's go!
            </Typography>
            <Typography variant="body1">
              Your plans have been made! Check your upcoming events to see your
              plans.
            </Typography>
          </div>
          <Button
            onClick={() => {
              navigate("/app/mylist");
            }}
            sx={{
              background: "#233162",
              color: "white",
            }}
          >
            My plans
            <ArrowRightAltIcon />
          </Button>
        </Stack>

        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            position: "absolute",
            left: "50%",
            bottom: "10px",
            transform: "translateX(-50%)",
            background: "lightgrey",
            borderRadius: "5px",
          }}
        >
          <Button
            sx={{
              color: "#233162",
            }}
            onClick={close}
          >
            Close
          </Button>
        </Stack>
      </>
    );
  } else {
    currentView = (
      <>
        <Stack
          direction="column"
          sx={{
            textAlign: "center",
            background: "#233162",
            color: "white",
            padding: "24px 12px 12px 12px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "700",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {info.EventName}
          </Typography>
          <Typography variant="body2">{info.LocationName}</Typography>
        </Stack>
        <Stack
          sx={{
            textAlign: "center",
            padding: "12px 6px",
          }}
          direction="column"
        >
          <Typography variant="body1" sx={{ fontStyle: "italic" }}>
            Invite your friends to this event. They'll get a notification when
            you do.
          </Typography>
        </Stack>
        <Stack>
          <SearchTable
            data={contactList}
            inviteList={inviteList}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            noResultsComponent={
              <EmptyContactsList
                setContactList={setContactList}
                eventId={info.EventId}
              ></EmptyContactsList>
            }
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#233162",
              position: "absolute",
              right: "12px",
              bottom: "12px",
            }}
            disabled={Object.keys(selectedIds).length === 0 || loading}
            onClick={() => {
              setLoading(true);
              axios
                .post(
                  `${SERVER_URL}/api/event/invite`,
                  JSON.stringify({
                    event_id: info.EventId,
                    person_ids: Object.keys(selectedIds),
                  })
                )
                .then(function (res) {
                  setLoading(false);
                  setInviteSent(true);
                })
                .catch(function (err) {
                  setLoading(false);
                  console.error("error sending invite", err);
                  // if (!userIsLoggedIn) {
                  //   navigate(`/app/auth/login?redirect=/app/mylist`);
                  // }
                });
            }}
          >
            {loading ? (
              <CircularProgress size="1rem" />
            ) : (
              <Typography>Invite</Typography>
            )}
          </Button>
        </Stack>
        <Stack
          direction="row"
          sx={{
            margin: "0 auto",
            position: "absolute",
            bottom: "12px",
            left: "12px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#E2E5DE",
              color: "#233162",
              marginInlineEnd: "8px",
              ":hover": {
                backgroundColor: "darkgrey",
              },
            }}
            onClick={close}
          >
            <ArrowBackIcon />
          </Button>
        </Stack>
      </>
    );
  }

  return <Box>{currentView}</Box>;
};
