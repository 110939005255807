import styled from "styled-components";
import { colors } from "../utils/colors";

import MenuIcon from "../assets/icons/menu.svg";
import FilterIcon from "../assets/icons/filter.svg";
import { useSafearea } from "./page";

const Wrapper = styled.div.attrs(({ $top }) => ({ $top }))`
  position: fixed;
  top: ${({ $top }) => $top + 8}px;
  right: 8px;
  z-index: 1;
  pointer-events: initial;
  color: black;
`;

const IconWrapper = styled.div`
  border: solid 1px ${colors.grey};
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  background: ${colors.white};
`;

const TopRightIcon = styled.img`
  width: 24px;
  height: 24px;
  padding: 8px;
  padding: auto;
  pointer-events: initial;
`;

const FilterSettings = styled.div.attrs(({ $opened }) => ({
  $opened,
}))`
  position: absolute;
  right: ${({ $opened }) => ($opened ? "0px" : "-400px")};
  top: 52px;
  max-width: 400px;
  border-radius: 16px;
  transition: right 0.2s;
  background: ${colors.white};
`;

export const DropdownMenu = props => {
  const { safearea } = useSafearea();
  return (
    <Wrapper
      className="cmp-dropdown-menu"
      onClick={e => { }}
      $top={safearea.top}
    >
      <TopRightIcon src={MenuIcon} />
    </Wrapper>
  );
};

export const FilterMenu = ({ content, opened, setOpened }) => {
  const { safearea } = useSafearea();
  return (
    <Wrapper className="cmp-filter-menu" $top={safearea.top}>
      <IconWrapper>
        <TopRightIcon
          src={FilterIcon}
          onClick={e => {
            setOpened(!opened);
          }}
        />
      </IconWrapper>
      <FilterSettings $opened={opened}>{content}</FilterSettings>
    </Wrapper>
  );
};
