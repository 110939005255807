import mapboxgl from "mapbox-gl";
import React, { useEffect, useState, useRef } from "react";
import { getCurrentLocation } from "../../hooks/location";
import { recommendEvents, getEventInfo } from "../../hooks/events";
import SelfMarker from "../../assets/marker.png";
import { EventsCard } from "../cards/EventsCard";
import { MapBoxToken } from "../../hooks/tokens";
import "./EventMap.css";
import { Spinner } from "../Spinner";
import { Button, Paper, Stack, Typography, Box } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { EventMapFilter } from "./EventMapFilter";
import "../../App.css";
import { daysFromDate } from "../../utils";
import Logo from "../../assets/logo.png";
import EntertainmentIcon from "../../assets/event-categories/entertainment.png";
import FoodAndDiningIcon from "../../assets/event-categories/food-and-dining.png";
import HappyHourIcon from "../../assets/event-categories/happy-hour.png";
import SportsAndActivitiesIcon from "../../assets/event-categories/sports.png";
import EntertainmentIconBlue from "../../assets/event-categories/entertainment-inactive.png";
import FoodAndDiningIconBlue from "../../assets/event-categories/food-and-dining-inactive.png";
import HappyHourIconBlue from "../../assets/event-categories/happy-hour-inactive.png";
import SportsAndActivitiesIconBlue from "../../assets/event-categories/sports-inactive.png";
import DotIcon from "../../assets/dot.png";
import { useAppContext } from "../../AppContext";

mapboxgl.accessToken = MapBoxToken;
let filter = {
  "Happy Hour": true,
  "Food & Dining": true,
  Entertainment: true,
  Activities: true,
};

export const EventMap = props => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zoom, setZoom] = useState(11);
  const [open, setOpen] = useState(false);
  // selected event info
  const [info, setInfo] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);
  const [mapTimeRange, setMapTimeRange] = useState("today");
  const [markers, setMarkers] = useState({});
  const [categoryFilter, setCategoryFilter] = useState({
    "Happy Hour": true,
    "Food & Dining": true,
    Entertainment: true,
    Activities: true,
  });
  const [events, setEvents] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [eventIdxMap, setEventIdxMap] = useState({});
  const [idx, setIdx] = useState(null);
  const [moveEndIsSet, setMoveEndIsSet] = useState(false);
  const { safearea, ratingRequestMetric, setRatingRequestMetric } = useAppContext();

  useEffect(() => {
    if (open && eventIds[idx]) {
      getEventInfo([eventIds[idx]]).then(resp => {
        if (resp.events.length > 0) {
          renderMarkers(events, true);
          const evt = resp.events[0];
          if (!evt.Longitude || !evt.Latitude) return;
          map.current.clicked = true;
          map.current.flyTo({
            center: [evt.Longitude, evt.Latitude],
            offset: [0, -180], // move to 1/3 way of the screen and then display popup on the bottom
            zoom: 15,
          });
          setInfo(evt);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx, open]);

  useEffect(() => {
    renderMarkers(events);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  function renderMarkers(evts, renderIcons = false) {
    Array.from(document.querySelectorAll(".event-icon,.dot-icon")).forEach(
      ele => {
        ele.remove();
      }
    );
    Object.values(markers).forEach(marker => {
      marker.remove();
    });
    const newMarkers = {};
    evts.forEach((evt, i) => {
      if (!evt.Longitude || !evt.Latitude) {
        return;
      }

      const selected = i === idx && open;
      const category = evt.Categories ? evt.Categories[0] : "";
      const img = document.createElement("img");
      const imgSrc =
        zoom > 12.5 || renderIcons
          ? {
            "Happy Hour": selected ? HappyHourIconBlue : HappyHourIcon,
            Activities: selected
              ? SportsAndActivitiesIconBlue
              : SportsAndActivitiesIcon,
            Entertainment: selected
              ? EntertainmentIconBlue
              : EntertainmentIcon,
            "Food & Dining": selected
              ? FoodAndDiningIconBlue
              : FoodAndDiningIcon,
          }[category] || DotIcon
          : DotIcon;

      img.setAttribute("class", imgSrc === DotIcon ? "dot-icon" : "event-icon");
      img.setAttribute("src", imgSrc);
      img.dataset.evt = JSON.stringify(evt);

      const marker = new mapboxgl.Marker(img)
        .setLngLat([evt.Longitude, evt.Latitude])
        .addTo(map.current);
      newMarkers[evt.EventId] = marker;
      marker.getElement().addEventListener("click", e => {
        const evt = JSON.parse(e.target.dataset.evt);
        setIdx(eventIdxMap[evt.EventId] || 0);
        setOpen(true);
        setRatingRequestMetric({ ...ratingRequestMetric, eventsViewCount: ratingRequestMetric.eventsViewCount + 1 })
      });
    });
    setMarkers(newMarkers);
  }

  function handleEventsData(longitude, latitude, zoom, resetMapIdx = false) {
    if (longitude === 0 && latitude === 0) return;
    let startingAfter, endingBefore;
    switch (mapTimeRange) {
      case "today":
        startingAfter = new Date();
        endingBefore = daysFromDate(startingAfter, 1);
        break;
      case "this-week":
        startingAfter = new Date();
        endingBefore = daysFromDate(startingAfter, 7);
        break;
      case "next-week":
        startingAfter = daysFromDate(new Date(), 7);
        endingBefore = daysFromDate(startingAfter, 7);
        break;
      default:
        break;
    }
    recommendEvents(
      longitude,
      latitude,
      parseInt(40000 / Math.pow(2, zoom)),
      100,
      startingAfter,
      endingBefore,
      filter
    )
      .then(data => {
        setEvents(data.events);
        if (eventIds.length <= data.events.length || resetMapIdx) {
          const newEventIdxMap = {};
          setEventIds(
            data.events.map((evt, i) => {
              newEventIdxMap[evt.EventId] = i;
              return evt.EventId;
            })
          );
          setEventIdxMap(newEventIdxMap);
        }
      })
      .catch(err => {
        console.error("get events data", err);
      });
  }

  useEffect(() => {
    if (!map.current) return;
    filter = categoryFilter;
    const newZoom = map.current.getZoom().toFixed(2);
    setZoom(newZoom);
    handleEventsData(
      map.current.getCenter().lng.toFixed(8),
      map.current.getCenter().lat.toFixed(8),
      newZoom,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter]);

  useEffect(() => {
    if (!map.current || moveEndIsSet) return;
    const newZoom = map.current.getZoom().toFixed(2);
    setZoom(newZoom);

    // Add event listeners for the map
    setMoveEndIsSet(true);
    map.current.off().on("moveend", () => {
      const newZoom = map.current.getZoom().toFixed(2);
      setZoom(newZoom);
      if (map.current.clicked) map.current.clicked = false;
      else {
        handleEventsData(
          map.current.getCenter().lng.toFixed(8),
          map.current.getCenter().lat.toFixed(8),
          newZoom,
          true
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map.current]);

  useEffect(() => {
    getCurrentLocation()
      .then(coords => {
        // Load map and zoom in to current location
        if (map.current) return;

        // initialize map only once
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/alexjoo93/clmtprvla020401r6c1rogwpu",
          center: [coords.longitude, coords.latitude],
          zoom: zoom,
        });

        map.current.once("load", () => {
          const spinner = document.getElementById("spinner");
          if (spinner) spinner.classList.toggle("hidden");
        });

        const selfMarker = document.createElement("img");
        selfMarker.setAttribute("src", SelfMarker);
        selfMarker.setAttribute("class", "self-marker");
        new mapboxgl.Marker(selfMarker)
          .setLngLat([coords.longitude, coords.latitude])
          .addTo(map.current);

        handleEventsData(coords.longitude, coords.latitude, zoom);
      })
      .catch(err => {
        console.error("get current location", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, categoryFilter]);

  return (
    <>
      {filterOpen && (
        <EventMapFilter
          open={filterOpen}
          setOpen={setFilterOpen}
          mapTimeRange={mapTimeRange}
          setMapTimeRange={setMapTimeRange}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
          setCardOpen={setOpen}
        />
      )}
      <Spinner text="fetching..." background="bg-solid" />
      <EventsCard
        info={info}
        markers={markers}
        eventsCount={eventIds.length}
        idx={idx}
        setIdx={setIdx}
        setOpen={setOpen}
        open={open}
      />
      <Paper
        sx={{
          background: "none",
          color: "white",
          paddingInline: "12px",
          zIndex: 1,
          position: "absolute",
          width: "-webkit-fill-available",
          boxShadow: "none",
        }}
      >
        <Stack
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "50px",
          }}
          direction="row"
        >
          <Box
            className="logo-box"
            style={{
              top: safearea?.top,
            }}
          >
            <img src={Logo} alt="Gyde" width="50px" />
            <Typography
              variant="h5"
              sx={{
                color: "#233162",
                fontFamily: "HemiHead",
                display: "inline-block",
              }}
            >
              Gyde
            </Typography>
          </Box>
          <Button
            sx={{
              position: "absolute",
              right: 0,
              top: safearea?.top,
            }}
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
          >
            <FilterListIcon
              style={{
                color: "#233162",
                background: "rgba(255, 255, 255, 0.9)",
                borderRadius: "10px",
                padding: "8px",
                boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 5px",
              }}
            />
          </Button>
        </Stack>
      </Paper>
      <div ref={mapContainer} className="map-container" />
    </>
  );
};
