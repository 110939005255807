/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import axios from "axios";
import { storage } from "./src-gql/utils/storage";
import { RateApp } from "capacitor-rate-app";
import { SERVER_URL } from "./utils";
import { useNavigate } from "react-router-dom";
import { SafeArea } from "capacitor-plugin-safe-area";
import { RetrieveContacts } from "./components/Contacts";

import { colors } from "./components/theme/colors";
import { Capacitor } from "@capacitor/core";

// let refresh,
//   refreshed = false;

// axios.interceptors.response.use(
//   result => {
//     return result;
//   },
//   err => {
//     if (err.response) {
//       switch (err.response.status) {
//         case 401:
//           if (!err.config.url.endsWith("/auth/refresh")) refresh(true);
//           return;
//       }
//     }
//     console.error(err);
//     return Promise.reject(err);
//   }
// );

type AppContextValue = {
  // User credentials
  // userIsLoggedIn: boolean;
  // user: any;
  // loadAuthState: (data: any) => any;
  // logout: () => void;
  setBottomAreaColor: (data: any) => void;
  topAreaColor: string;
  setTopAreaColor: (data: any) => void;
  safearea: any;

  // Restaurant data for RR
  data: any;
  setData: (data: any) => void;
  // Users saved restaurant list
  // restaurantList: any[];
  // setRestaurantList: (restaurantList: any[]) => void;
  // Restaurant Query
  restaurantQuery: any;
  setRestaurantQuery: (q: any) => void;
  // Show start modal or not
  startModal: boolean;
  setStartModal: (startModal: boolean) => void;
  // Update dropdown options for cities to filter through
  updatedRestaurantCities: any[];
  setUpdatedRestaurantCities: (updatedRestaurantCities: any[]) => void;
  // user saved event list
  // eventList: any[];
  // setEventList: (eventList: any[]) => void;
  // filtered city for events list
  updatedEventsCities: any[];
  setUpdatedEventsCities: (updatedEventsCities: any[]) => void;
  // Tracks when to request users to rate our app
  ratingRequestMetric: any;
  setRatingRequestMetric: (obj: any) => void;
};

type SafeareaValue = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export const AppContext = createContext<AppContextValue>({} as any);

export const useAppContext = () => useContext(AppContext);

export const AppProvider = (props: any) => {
  const [restaurantQuery, setRestaurantQuery] = useState<any>(null);
  // const [restaurantList, setRestaurantList] = useState<Array<any>>([]);
  const [data, setData] = useState(undefined);
  const [startModal, setStartModal] = useState<boolean>(true);
  const [updatedRestaurantCities, setUpdatedRestaurantCities] = useState<
    Array<any>
  >([]);
  // const [eventList, setEventList] = useState<Array<any>>([]);
  const [updatedEventsCities, setUpdatedEventsCities] = useState<Array<any>>(
    []
  );

  // const [userIsLoggedIn, setUserIsLoggedIn] = useState<boolean>(false);
  // const [user, setUser] = useState<any>({});
  const navigate = useNavigate();

  const [safearea, setSafearea] = useState<SafeareaValue>({
    top: 0,
    bottom: 0,
  } as any);
  const [topAreaColor, setTopAreaColor] = useState<string>(colors.transparent);
  const [bottomAreaColor, setBottomAreaColor] = useState<string>(colors.blue);
  const [ratingRequestMetric, setRatingRequestMetric] = useState<any>({
    eventsViewCount: 0,
    restaurantsViewCount: 0,
    eventsSavedCount: 0,
    restaurantsSavedCount: 0,
  });

  // refresh = ignoreRedirect => {
  //   if (userIsLoggedIn) return;
  //   const refreshToken = storage.getItem('refreshToken')
  //   axios
  //     .post(
  //       `${SERVER_URL}/api/auth/refresh`,
  //       {},
  //       {
  //         headers: {
  //           "X-Refresh-Token": refreshToken,
  //         },
  //       }
  //     )
  //     .then(resp => {
  //       axios.defaults.headers.common["Authorization"] =
  //         resp.data.access_token;
  //       axios.defaults.withCredentials = true;
  //       storage.setItem("refreshToken", resp.data.refresh_token)
  //       storage.setItem("accessToken", resp.data.access_token)
  //       const newUser = storage.getItem("userProfile")
  //       newUser.person_id = resp.data.person_id;
  //       setUser(newUser);
  //       setUserIsLoggedIn(true);
  //     })
  //     .catch(err => {
  //       if (!ignoreRedirect) navigate(`/app/auth/login?redirect=/app`);
  //     });
  // }

  const loadAuthState = data => {
    // setUserIsLoggedIn(true);
    axios.defaults.headers.common["Authorization"] = data.access_token;
    axios.defaults.withCredentials = true;
    storage.setItem("refreshToken", data.refresh_token);
    storage.setItem("accessToken", data.access_token);
    // storage.setItem("userProfile", {
    //   person_id: data.personId,
    //   first_name: data.first_name,
    //   last_name: data.last_name,
    //   pfp_url: data.pfp_url,
    // })
    // setUser(data);
    // RetrieveContacts();
  };

  // const logout = () => {
  //   storage.clear()
  //   delete axios.defaults.headers.common["Authorization"];
  //   // setUserIsLoggedIn(false);
  //   setUser({});
  //   switch (location.pathname) {
  //     case "/app/mylist":
  //       navigate(`/app/auth/login?redirect=${location.pathname}`);
  //       break;
  //     default:
  //       navigate("/app");
  //   }
  // };

  // useEffect(() => {
  //   if (userIsLoggedIn || refreshed) return;
  //   switch (location.pathname) {
  //     case "/app/mylist":
  //       refresh();
  //       refreshed = true;
  //       break;
  //   }
  //   setUser(storage.getItem("userProfile"))
  // }, [userIsLoggedIn, location.pathname]);

  // useEffect(() => {
  //   setRatingRequestMetric(storage.getItem("ratingRequestMetric"));
  //   SafeArea.getSafeAreaInsets().then(({ insets }) => {
  //     if (Capacitor.getPlatform() === "ios") {
  //       setSafearea(insets);
  //     }
  //   });
  //   if (refreshed) return;
  //   refreshed = true;
  //   refresh(true);
  // }, []);

  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === "ios") {
        setSafearea(insets);
      }
    });
  }, [])

  useEffect(() => {
    if (
      ratingRequestMetric?.eventsViewCount > 3 &&
      ratingRequestMetric?.restaurantsViewCount > 3 &&
      (ratingRequestMetric?.eventsSavedCount > 0 ||
        ratingRequestMetric?.restaurantsSavedCount > 0)
    ) {
      storage.setItem("ratingRequestMetric", { requested: true });
    } else {
      storage.setItem("ratingRequestMetric", ratingRequestMetric || {});
    }
  }, [ratingRequestMetric]);

  const contextValue = useMemo(
    () => ({
      data,
      // restaurantList,
      // setRestaurantList,
      restaurantQuery,
      setRestaurantQuery,
      setData,
      startModal,
      setStartModal,
      updatedRestaurantCities,
      setUpdatedRestaurantCities,
      // eventList,
      // setEventList,
      updatedEventsCities,
      setUpdatedEventsCities,
      ratingRequestMetric,
      setRatingRequestMetric,

      // userIsLoggedIn,
      // user,
      loadAuthState,
      // logout,
      safearea,
      topAreaColor,
      setTopAreaColor,
      setBottomAreaColor,
    }),
    [
      data,
      // restaurantList,
      // setRestaurantList,
      restaurantQuery,
      setRestaurantQuery,
      setData,
      startModal,
      setStartModal,
      updatedRestaurantCities,
      setUpdatedRestaurantCities,
      // eventList,
      // setEventList,
      updatedEventsCities,
      setUpdatedEventsCities,
      ratingRequestMetric,
      setRatingRequestMetric,

      // userIsLoggedIn,
      // user,
      loadAuthState,
      // logout,
      safearea,
      topAreaColor,
      setTopAreaColor,
      setBottomAreaColor,
    ]
  );
  return (
    <AppContext.Provider value={contextValue}>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          height: safearea?.top,
          background: topAreaColor,
          zIndex: 5,
        }}
      ></div>
      {props.children}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          height: safearea?.bottom,
          background: bottomAreaColor,
        }}
      ></div>
    </AppContext.Provider>
  );
};
