import styled from "styled-components";
import { useSafearea } from "./page";
import { colors } from "../utils/colors";
import EventIcon from "@mui/icons-material/Event";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppContext } from "../../AppContext";

const Wrapper = styled.div.attrs(({ $bottom }) => ({
  $bottom,
}))`
  position: absolute;
  border-radius: 16px;
  left: 4px;
  bottom: ${({ $bottom }) => $bottom + 4}px;
  width: 98%;
  background-color: ${colors.blue};
  z-index: 10;
`;

const Tabs = styled.div`
  display: grid;
  height: 50px;
  width: 100%;
  max-width: 480px;
  grid-template-columns: 1fr 1fr;
  margin: auto;
`;

const Button = styled.button`
  margin: 0 auto;
  position: fixed;
  cursor: pointer;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Navbar = props => {
  const { safearea } = useSafearea();
  const navigate = useNavigate();
  const { userIsLoggedIn } = useAppContext();
  const [selectedTab, setSelectedtab] = useState("restaurants");
  return (
    <Wrapper className="cmp-navbar" $bottom={safearea.bottom}>
      <Tabs>
        <ButtonDiv>
          <Button
            onClick={() => {
              setSelectedtab("restaurants");
              navigate("/app/");
            }}
          >
            <RestaurantIcon
              style={{
                color:
                  selectedTab === "restaurants" ? colors.orange : colors.white,
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        </ButtonDiv>
        <ButtonDiv
          onClick={() => {
            setSelectedtab("events");
            navigate("/app/events");
          }}
        >
          <Button>
            <EventIcon
              style={{
                color: selectedTab === "events" ? colors.orange : colors.white,
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        </ButtonDiv>
        {/* <ButtonDiv
          onClick={() => {
            setSelectedtab("mylist");
            if (!userIsLoggedIn) {
              navigate("/app/auth/login?redirect=/app/mylist");
            } else {
              navigate("/app/mylist");
            }
          }}
        >
          <Button>
            <FormatListBulletedIcon
              style={{
                color: selectedTab === "mylist" ? colors.orange : colors.white,
                width: "20px",
                height: "20px",
              }}
            />
          </Button>
        </ButtonDiv> */}
      </Tabs>
    </Wrapper>
  );
};
