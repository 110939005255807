import styled from 'styled-components'

import LogoIcon from "../assets/logo.png";
import { colors } from '../utils/colors';
import { useSafearea } from './page';

const Wrapper = styled.div.attrs(({ $visible, $background, $top }) => ({
    $visible, $background, $top
}))`
    background: ${({ $background }) => $background};
    ${({ $background }) => $background !== 'transparent' ? `
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    `: ''}
    top: ${({ $top }) => $top}px;
    position: relative;
    display: ${({ $visible }) => $visible ? "initial" : "none"};
    z-index: 3;
    pointer-events: ${({ $background }) => $background === 'transparent' ? 'none' : 'initial'};
`

const LogoWrapper = styled.div`
    font-family: HemiHead;
    font-size: 20px;
    display: flex;
    cursor: pointer;
    width: fit-content;
`

const Logo = styled.img`
    width: 40px;
    height: 40px;
    padding: 5px;
`

const LogoText = styled.div`
    line-height: 50px;
`

export const TopBar = ({ content, background, visible }) => {
    const { safearea } = useSafearea();
    return <Wrapper className="cmp-topbar" $visible={visible} $background={background || colors.transparent} $top={safearea.top}>
        <LogoWrapper>
            <Logo src={LogoIcon}></Logo>
            <LogoText>Gyde</LogoText>
        </LogoWrapper>
        {content || ""}
    </Wrapper>
};