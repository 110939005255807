import styled from "styled-components";
import { useState } from "react";
import intl from "react-intl-universal";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { GooglePlacesSearchToken } from "../utils/tokens";
import { colors } from "../utils/colors";
import { Toggle } from "./toggle";
import { getCurrentLocation } from "../../hooks/location";

import CloseIcon from "../assets/icons/close.svg";

const InputWrapper = styled.div`
  position: relative;
`;

const Error = styled.div`
  color: ${colors.orange};
  text-align: center;
`;

const StyledInput = styled.input.attrs(({ $focused }) => ({
  $focused,
}))`
  padding: 0.25em;
  border: none;
  border-bottom: solid 1px
    ${({ $focused }) => ($focused ? colors.lightblue : colors.blue)};
  width: calc(100% - 2em);
  font-size: 16px;
  margin-right: 1.5em;
`;

const ClearButton = styled.img`
  position: absolute;
  right: 0;
  cursor: pointer;
`;

export const Input = ({ name, label, errMsg, placeholder, defaultValue }) => {
  const [focused, setFocused] = useState(false);
  const [v, setV] = useState(defaultValue);
  return (
    <div className="cmp-input" style={{ marginBlockStart: "4px" }}>
      {label ? <div>{label}</div> : ""}
      <InputWrapper>
        <StyledInput
          data-key={name}
          value={v}
          $focused={focused}
          onChange={e => {
            setV(e.target.value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          placeholder={placeholder}
        ></StyledInput>
        <ClearButton
          src={CloseIcon}
          onClick={e => {
            setV("");
            e.preventDefault();
          }}
        />
      </InputWrapper>
      {errMsg ? <Error>{errMsg}</Error> : ""}
    </div>
  );
};

const ToggleWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const AutocompleteWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  background: yellow;
`;

const Label = styled.div`
  position: relative;
`;

export const LocationInput = ({
  label,
  defaultLocation,
  deafultCoordinates,
  onChange,
  onCoordinatesFetch,
  onCoordinates,
  withCurrentLocation,
}) => {
  const [enableAC, setEnableAC] = useState(false);
  const [locationV, setLocationV] = useState(defaultLocation || "");
  const [coordinatesV, setCoordinatesV] = useState(deafultCoordinates || {});
  return (
    <InputWrapper>
      {label ? <Label>{label}</Label> : ""}
      {withCurrentLocation ? (
        <ToggleWrapper
          data-key="coordinates"
          value={JSON.stringify(coordinatesV)}
        >
          <Toggle
            defaultValue={true}
            label={intl.get("currentLocation")}
            onSwitch={(e, isOn) => {
              setEnableAC(!isOn);
              if (isOn) {
                if (onCoordinatesFetch) onCoordinatesFetch();
                getCurrentLocation().then(coor => {
                  if (onCoordinates) onCoordinates(e, isOn, coor);
                  setCoordinatesV({
                    longitude: coor.longitude,
                    latitude: coor.latitude,
                  });
                });
              } else {
                if (onCoordinates) onCoordinates(e, isOn);
              }
            }}
          />
        </ToggleWrapper>
      ) : (
        ""
      )}
      {enableAC ? (
        <AutocompleteWrapper
          data-key="location"
          value={JSON.stringify(locationV)}
        >
          <GooglePlacesAutocomplete
            fetchDetails={true}
            apiKey={GooglePlacesSearchToken}
            value={
              enableAC
                ? `${locationV?.city} ${locationV?.stateCode} ${locationV?.countryCode}`
                : ""
            }
            selectProps={{
              onChange: loc => {
                const l = loc?.value?.terms?.length;
                if (l === 0) return;
                const newLocation = {
                  countryCode: loc.value.terms[l - 1].value,
                  stateCode: loc.value.terms[l - 2]
                    ? loc.value.terms[l - 2].value
                    : "",
                  city: loc.value.terms[l - 3]
                    ? loc.value.terms[l - 3].value
                    : "",
                };
                geocodeByPlaceId(loc.value.place_id).then(results => {
                  newLocation.latitude = results[0].geometry.location.lat();
                  newLocation.longitude = results[0].geometry.location.lng();
                  if (onChange) onChange(newLocation);
                });
                setLocationV(newLocation);
              },
            }}
          />
        </AutocompleteWrapper>
      ) : (
        ""
      )}
    </InputWrapper>
  );
};
