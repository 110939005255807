import React, { FC } from "react";
import { Link, Box, Grid, Typography, Stack } from "@mui/material";
import DottedPath from "../components/assets/dotted-path.svg";
import DogRunning from "../components/assets/dog-running.gif";
import Mobile2 from "../components/assets/iphone2.png";
import Mobile1 from "../components/assets/iphone.png";
import Magnify from "../components/assets/magnify.png";
import Plate from "../components/assets/plate.png";
import Rsvp from "../components/assets/rsvp.png";
import AppStore from "../components/assets/DownloadAppStore.svg";
import { colors } from "../../theme/colors";
import { Link as RouterLink } from "react-router-dom";

const HomeHero: FC = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        paddingBottom: "24px",
        background:
          "rgb(239,82,71) linear-gradient(180deg, rgba(239,82,71,1) 0%, rgba(239,82,71,1) 30%, rgba(175,72,79,1) 100%)",
      }}
    >
      <Grid className="outer-grid" container spacing={0}>
        <Grid item lg={12} sm={12}>
          <Box sx={{ mb: 3 }}>
            <Typography
              component="h4"
              sx={{
                position: "relative",
                fontSize: { xs: 42, md: 56 },
                letterSpacing: 1.5,
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "center",
                color: "white",
                marginBlockStart: "12px",
              }}
            >
              Making plans should be easy
            </Typography>
          </Box>
          <Box
            sx={{
              position: "relative",
              marginBottom: "24px",
            }}
          >
            <img
              src={DottedPath}
              alt="App"
              width="100%"
              style={{
                position: "absolute",
                width: "100%",
                left: "0",
                top: "42%",
                transform: "translateY(-100%)",
              }}
            ></img>
            <div
              style={{
                position: "absolute",
                width: "250px",
                height: "250px",
                left: "50%",
                top: "33%",
                transform: "translate(-50%, -50%)",
                borderRadius: "250px",
                background: "white",
              }}
            ></div>
            <img
              src={Mobile2}
              alt="App"
              width="220px"
              height="350px"
              style={{
                transform: "rotate(10deg) translateY(10%)",
                marginTop: "-2.5%",
              }}
            />
            <Typography
              sx={{
                color: "white",
                lineHeight: 1.6,
                maxWidth: "500px",
                margin: "auto",
                padding: "25px",
                fontSize: "20px",
                paddingTop: "50px",
              }}
              variant="body2"
            >
              Let us be your Gyde and help you discover restaurants, events, and
              activites near you.
            </Typography>

            <Link
              target="_blank"
              href={"https://apps.apple.com/us/app/gyde-live/id6471469614"}
              sx={{
                margin: "15px",
              }}
            >
              <img
                src={AppStore}
                alt="Download in the Apple App Store"
                style={{
                  height: "50px",
                  margin: "0 auto",
                }}
              />
            </Link>
          </Box>
          <Link component={RouterLink} to="/app" sx={{ color: colors.white }}>
            Try it now
          </Link>
        </Grid>
      </Grid>

      {/* <Box sx={{ background: colors.blue }}>
        <Grid
          container
          sx={{
            backgroundColor: colors.blue,
            justifyContent: "center",
            alignContent: "center",
            color: "white",
            flexDirection: { lg: "row", md: "column" },
            marginBlockStart: "200px",
            marginBlockEnd: { xs: "100px", md: "200px", lg: "200px" },
            position: "relative",
          }}
        >
          <img
            alt="landing"
            src={DogRunning}
            style={{
              position: "absolute",
              width: "128px",
              right: "5%",
              top: "-100px",
            }}
          />
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            sx={{ textAlign: "center", marginBlock: "-100px", zIndex: 1 }}
          >
            <img src={Mobile1} alt="App" width="400px" height="600px" />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Stack
              direction="column"
              sx={{
                maxWidth: "500px",
                margin: "100px auto",
                padding: "50px 25px",
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 600 }}>
                Dicover your neighborhood
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "20px", marginTop: "12px" }}
              >
                With Gyde, discover exciting restaurants at the touch of a
                button and explore real-time events on an interactive map.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box> */}

      {/* <Box sx={{ paddingBlockEnd: { lg: "200px", md: "100px", xs: "100px" } }}>
        <Grid
          container
          sx={{
            justifyContent: "center",
          }}
        >
          <Stack
            spacing={4}
            direction={{ md: "row", xs: "column" }}
            sx={{
              justifyContent: "center",
              alignItems: { xs: "center" },
            }}
          >
            <Box
              sx={{
                width: "350px",
                height: "450px",
                borderRadius: "16px",
                margin: "0 auto",
                background: "white",
                textAlign: "center",
              }}
            >
              <img
                src={Plate}
                alt="App"
                width="200px"
                height="200px"
                style={{ padding: "24px" }}
              />

              <Typography
                variant="h6"
                sx={{
                  backgroundColor: colors.blue,
                  color: "white",
                  padding: "12px",
                  marginBlockEnd: "24px",
                }}
              >
                Simplify dining decisions
              </Typography>
              <Typography variant="body2" sx={{ paddingInline: "24px" }}>
                Gyde takes the guesswork out of dining by instantly suggesting
                unique and delicious for a delightful and fun dining
                experiences!
              </Typography>
            </Box>
            <Box
              sx={{
                width: "350px",
                height: "450px",
                borderRadius: "16px",
                margin: "0 auto",
                background: "white",
                textAlign: "center",
              }}
            >
              <img
                src={Magnify}
                alt="App"
                width="200px"
                height="200px"
                style={{ padding: "24px" }}
              />

              <Typography
                variant="h6"
                sx={{
                  backgroundColor: colors.blue,
                  color: "white",
                  padding: "12px",
                  marginBlockEnd: "24px",
                }}
              >
                Events in real-time
              </Typography>
              <Typography variant="body2" sx={{ paddingInline: "24px" }}>
                Explore with the interactive map in real-time to discover
                anything from happy hours to cultural festivals.
              </Typography>
            </Box>
            <Box
              sx={{
                width: "350px",
                height: "450px",
                borderRadius: "16px",
                margin: "0 auto",
                background: "white",
                textAlign: "center",
              }}
            >
              <img
                src={Rsvp}
                alt="App"
                width="200px"
                height="200px"
                style={{ padding: "24px" }}
              />

              <Typography
                variant="h6"
                sx={{
                  backgroundColor: colors.blue,
                  color: "white",
                  padding: "12px",
                  marginBlockEnd: "24px",
                }}
              >
                Connect with friends
              </Typography>
              <Typography variant="body2" sx={{ paddingInline: "24px" }}>
                Effortlesly make plans and invite friends to events. You'll see
                event information and RSVP's instantly.
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default HomeHero;
