import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  SwipeableDrawer,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useAppContext } from "../../AppContext";
import PrivacyPolicyModal from "../PrivacyPolicyModal";

export const EventMapFilter = ({
  open,
  setOpen,
  setMapTimeRange,
  setCategoryFilter,
  mapTimeRange,
  categoryFilter,
  setCardOpen,
}) => {
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const [rangeValue, setRangeValue] = useState(mapTimeRange);
  const [categoryValue, setCategoryValue] = useState(categoryFilter);
  const { safearea } = useAppContext();

  const updateCheckBox = e => {
    categoryValue[e.target.defaultValue] =
      !categoryValue[e.target.defaultValue];
    setCategoryValue({ ...categoryValue });
  };

  return (
    <SwipeableDrawer
      anchor="top"
      id={"map-filter"}
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
      componentsProps={{
        backdrop: {
          style: {
            opacity: "20%",
          },
        },
      }}
      PaperProps={{
        sx: {
          position: "absolute",
          top: `calc(56px + ${safearea?.top}px)`,
          left: "unset",
          right: "10px",
          maxWidth: "400px",
          width: "calc(100% - 20px)",
          borderRadius: "15px",
        },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "#f5fcff",
        }}
      >
        <Stack
          direction="column"
          sx={{
            height: "fit-content",
            display: "flex",
            alignItems: "baseline",
            padding: "12px",
            justifyContent: "center",
          }}
        >
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Time Range</FormLabel>
            <RadioGroup
              aria-labelledby="filter-radio-buttons"
              defaultValue={0}
              name="radio-buttons-group"
              row
              onChange={e => {
                setRangeValue(e.target.value);
              }}
            >
              <FormControlLabel
                checked={rangeValue === "today"}
                control={<Radio />}
                label="Today"
                value="today"
              />
              <FormControlLabel
                checked={rangeValue === "this-week"}
                control={<Radio />}
                label="This Week"
                value="this-week"
              />
              <FormControlLabel
                control={<Radio />}
                checked={rangeValue === "next-week"}
                label="Next Week"
                value="next-week"
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">Category</FormLabel>
            <FormControlLabel
              checked={categoryValue["Happy Hour"]}
              control={<Checkbox disabled={categoryValue.length === 1} />}
              label="Happy Hour"
              value="Happy Hour"
              onChange={updateCheckBox}
            />
            <FormControlLabel
              checked={categoryValue["Food & Dining"]}
              control={<Checkbox />}
              label="Food & Dining"
              value="Food & Dining"
              onChange={updateCheckBox}
            />
            <FormControlLabel
              checked={categoryValue["Entertainment"]}
              control={<Checkbox />}
              label="Entertainment"
              value="Entertainment"
              onChange={updateCheckBox}
            />
            <FormControlLabel
              checked={categoryValue["Activities"]}
              control={<Checkbox />}
              label="Activities"
              value="Activities"
              onChange={updateCheckBox}
            />
          </FormControl>
          <Button
            variant="contained"
            sx={{ margin: "0 auto " }}
            onClick={() => {
              setOpen(false);
              setMapTimeRange(rangeValue);
              setCardOpen(false);
              setCategoryFilter(
                Object.keys(categoryValue).filter(k => categoryValue[k])
                  .length === 0
                  ? {
                    "Happy Hour": true,
                    "Food & Dining": true,
                    Entertainment: true,
                    Activities: true,
                  }
                  : categoryValue
              );
            }}
          >
            Apply
          </Button>
        </Stack>

        <Stack>
          <PrivacyPolicyModal />
        </Stack>
      </Paper>
    </SwipeableDrawer>
  );
};
