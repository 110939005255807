import { gql } from 'urql';

export const RecommendRestaurantQuery = gql`
query recommendRestaurant($input: RecommendRestaurantRequest!){
    recommendRestaurant(input: $input){
        total
        business{
            id
            name
            price
            rating
            url
            categories
            coordinates{
                latitude
                longitude
            }
            phone
            photos
            hours{
                hours_type
                open{
                    is_overnight
                    start
                    end
                    day
                }
            }
            location{
                address_1
                address_2
                address_3
                city
                country
                state
                zip_code
                cross_streets
            }
        }
        is_saved
        new_data
    }
}
`;