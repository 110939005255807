import styled from "styled-components";
import intl from "react-intl-universal";

import { FilterMenu } from "../menu";
import { SecondaryButton } from "../buttons";
import { Input, LocationInput } from "../input";
import { Form } from "../form";
import { Slider } from "../slider";
import { useState } from "react";

const FilterFormWrapper = styled.div`
  max-width: 400px;
  width: 375px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  z-index: -1;
`

export const BusinessMapFilter = ({
  resp,
  input,
  setInput,
  fetchingCoor,
  setFetchingCoor,
  usingCurrLoc,
  setUsingCurrLoc,
  menuOpened,
  setMenuOpened,
}) => {
  const texts = intl.get("restaurantFilter");
  const { categories, price, radius, location } = input;
  const [locationV, setLocationV] = useState(location || "");

  return (
    <>
      <FilterMenu
        setOpened={setMenuOpened}
        opened={menuOpened}
        content={
          <FilterFormWrapper>
            {menuOpened ? <Backdrop /> : ""}
            <Form
              disable={resp.fetching}
              onSubmit={d => {
                if (usingCurrLoc) {
                  try {
                    delete input.location;
                  } catch (err) { }
                  try {
                    delete d.location;
                  } catch (err) { }
                  d.coordinates = input.coordinates || d.coordinates;
                } else {
                  try {
                    delete input.coordinates;
                  } catch (err) { }
                  try {
                    delete d.coordinates;
                  } catch (err) { }
                  if (d.location)
                    d.location = `${d.location?.city}, ${d.location?.stateCode}, ${d.location?.countryCode}`;
                }
                setInput({ ...input, ...d });
                setMenuOpened(false);
              }}
            >
              <LocationInput
                defaultCoordinates={input.coordinates}
                locationV={locationV}
                setLocationV={setLocationV}
                label={texts.location}
                placeholder={texts.locationPlaceholder}
                withCurrentLocation
                onCoordinatesFetch={() => {
                  setFetchingCoor(true);
                }}
                onCoordinates={(e, isOn) => {
                  setUsingCurrLoc(isOn);
                  setFetchingCoor(false);
                }}
              />

              <Input
                name="categories"
                defaultValue={categories}
                label={texts.cuisine}
                placeholder={texts.cuisinePlaceholder}
              />
              <Slider
                name="radius"
                defaultValue={radius}
                label={texts.radius}
                min={0.5}
                max={20}
                unit={texts.radiusUnit}
                toFixed={1}
              />
              <Slider
                name="price"
                defaultValue={price}
                label={texts.price}
                min={1}
                max={4}
                withChar="$"
                round
              />
              <ButtonWrapper>
                <SecondaryButton
                  type="submit"
                  loading={resp.fetching || fetchingCoor}
                  disabled={!usingCurrLoc && locationV === ""}
                >
                  {intl.get("SPIN")}
                </SecondaryButton>
              </ButtonWrapper>
            </Form>
          </FilterFormWrapper>
        }
      />
    </>
  );
};
