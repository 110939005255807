import React, { FC } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SocialLinks from "./footer-social-links";
import FooterNavigation from "./footer-navigation";

const Footer: FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#233162",
        py: { xs: 6, md: 12 },
        color: "#fbfbfb",
        textAlign: "center",
        display: "flex",
        margin: "auto",
      }}
    >
      <Container>
        <FooterNavigation />
      </Container>
    </Box>
  );
};

export default Footer;
