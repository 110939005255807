import React from "react";
import HomeHero from "../home/hero";
import NewsLetter from "../home/newsletter";
import { Box } from "@mui/material";
import { Footer } from "../footer";
import { ResponsiveAppBar } from "../header/header";

const Home = () => {
  return (
    <>
      <Box component="main" sx={{ display: "block" }}>
        <ResponsiveAppBar />
        <HomeHero />
        {/* <NewsLetter /> */}
        <Footer />
      </Box>
    </>
  );
};

export default Home;
