import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FooterSectionTitle from "./footer-section-title";
import { Link } from "react-router-dom";
import SocialLinks from "./footer-social-links";
import { Box, Typography } from "@mui/material";

export interface Navigation {
  label: string;
  path: string;
}

const companyMenu: Array<Navigation> = [
  { label: "Contact Us", path: "mailto:hello@gyde.live" },
  { label: "Privacy Policy", path: "/landing/privacy" },
];

interface NavigationItemProps {
  label: string;
  path: string;
}

const NavigationItem: FC<NavigationItemProps> = ({ label, path }) => {
  return (
    <Link to={path}>
      <MuiLink
        underline="hover"
        sx={{
          display: "block",
          mb: 1,
          color: "#fbfbfb",
        }}
      >
        {label}
      </MuiLink>
    </Link>
  );
};

const FooterNavigation: FC = () => {
  return (
    <Box>
      <FooterSectionTitle title="About" />
      {companyMenu.map(({ label, path }, index) => (
        <NavigationItem key={index + path} label={label} path={path} />
      ))}
      <SocialLinks />
      <Typography variant="caption" sx={{ letterSpacing: 1, mb: 2 }}>
        Gyde is a mobile and web app operating since 2023.
      </Typography>
    </Box>
  );
};

export default FooterNavigation;
