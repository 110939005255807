/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from "react";
import { FixedBottomNavigation } from "../components/BottomNav";
import { EventMap } from "../components/maps/EventMap";
import { Alert, Button, Collapse, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppContext } from "../AppContext";
import { colors } from "../components/theme/colors";
import { storage } from "../src-gql/utils/storage";

export const EventMapPage = () => {
  const [open, setOpen] = useState(false);
  const { setTopAreaColor, setBottomAreaColor, safearea } = useAppContext();

  useEffect(() => {
    setTopAreaColor("transparent");
    setBottomAreaColor("transparent");
    setOpen(storage.getItem("hideBanner"));
  }, []);

  return (
    <>
      {open ? (
        <Stack direction="row">
          <Collapse in={open} />
          <Alert
            sx={{
              alignItems: "center",
              width: "calc(100% - 64px)",
              margin: "16px",
              padding: "6px 16px",
              position: "fixed",
              top: safearea?.top,
              zIndex: 100,
              marginBlockStart: "56px",
            }}
            severity="warning"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  storage.setItem("hideBanner", true);
                  setOpen(false);
                }}
                sx={{ alignItems: "center" }}
              >
                <CloseIcon fontSize="small" />
              </Button>
            }
          >
            Our events feature is currently limited to the Bay Area.{" "}
            <strong>Check it out!</strong>
          </Alert>
        </Stack>
      ) : (
        ""
      )}

      <EventMap />
      {/* <FixedBottomNavigation /> */}
    </>
  );
};
