import axios from "axios";
import { SERVER_URL, daysFromDate } from "../utils";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

let cancel;

export const recommendEvents = (
  longitude,
  latitude,
  milesRadius = 5,
  limit = 100,
  startingAfter,
  endingBefore,
  categories,
) => {
  if (cancel) cancel();
  if (!startingAfter) startingAfter = new Date();
  if (!endingBefore) endingBefore = daysFromDate(startingAfter, 14);
  return new Promise((resolve, reject) => {
    axios
      .create({
        cancelToken: new axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      })
      .post(
        `${SERVER_URL}/api/recommend/events`,
        JSON.stringify({
          limit: limit,
          radius: milesRadius,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          timezone: timezone,
          starting_after: startingAfter,
          ending_before: endingBefore,
          categories: Object.keys(categories).filter(function (key) {
            return categories[key]
          }),
        })
      )
      .then(resp => resolve(resp.data))
      .catch((err) => {
        if (err.code === "ERR_CANCELED") return;
        reject(err);
      });
  });
};

export const getEventInfo = (eventIds) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${SERVER_URL}/api/event/info`,
        JSON.stringify({
          eventIds: eventIds,
          timezone: timezone,
        })
      )
      .then(resp => resolve(resp.data))
      .catch(reject);
  });
}