import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import $ from "jquery";

import SliderSVG from "../assets/icons/solid-slider.png";
import { colors } from "../utils/colors";

const Wrapper = styled.div`
  position: relative;
`;

const SliderWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 0.25em 0;
  width: 75%;
  left: 10%;
  cursor: pointer;
`;

const DragEle = styled.div`
  width: 100%;
  height: 24px;
  position: absolute;
  opacity: 0;
`;

const SliderImg = styled.img`
  width: 100%;
  pointer-events: none;
  user-select: none;
`;
const Knob = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 20px;
  font-size: 16px;
  position: absolute;
  border: solid 2px ${colors.darkgrey};
  top: 3px;
  background-color: ${colors.white};
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  user-select: none;
`;

const Text = styled.div`
  position: absolute;
  font-size: 15px;
  margin: 0.25em 0;
`;

const MinText = styled(Text)`
  left: 0;
  bottom: 0;
`;
const MaxText = styled(Text)`
  right: 0;
  bottom: 0;
`;

const Value = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 0.25em;
`;

export const Slider = ({
  label,
  name,
  min,
  max,
  defaultValue,
  unit,
  withChar,
  round,
  toFixed,
}) => {
  const [v, setV] = useState(defaultValue);
  const ref = useRef(null);
  const onDrag = e => {
    if (e.touches && !e.touches?.length) return;
    const parent = $(e.target).parent();
    const x =
      (e.touches ? e.touches[0].pageX : e.pageX) - parent.offset().left - 10;
    const width = parent.width() * 0.9;
    const left = x < 0 ? 0 : x > width ? width : x;
    $(e.target).siblings(".subcmp-knob").css("left", left);
    setV(min + ((max - min) * left) / width);
  };

  useEffect(() => {
    const parent = $(ref.current).parent();
    const width = parent.width() * 0.9;
    const left = (width * (defaultValue - min)) / (max - min);
    $(ref.current).css("left", left);
  }, []);

  const value = toFixed ? v.toFixed(toFixed) : round ? Math.round(v) : v;

  return (
    <Wrapper
      className="cmp-slider"
      data-key={name}
      value={value}
    >
      {label ? <div>{label}</div> : ""}
      <MinText>
        {(() => {
          if (withChar) {
            return withChar.repeat(Math.round(min));
          }
          return min;
        })()}
      </MinText>
      <SliderWrapper>
        <DragEle
          draggable="true"
          onDrag={onDrag}
          onTouchMove={onDrag}
          onDragEnd={onDrag}
          onTouchEnd={onDrag}
        />
        <SliderImg src={SliderSVG} />
        <Knob ref={ref} className="subcmp-knob"></Knob>
      </SliderWrapper>
      <MaxText>
        {(() => {
          if (withChar) {
            return withChar.repeat(Math.round(max));
          }
          return max;
        })()}
      </MaxText>
      <Value>
        {(() => {
          if (withChar) {
            return withChar.repeat(Math.round(v));
          }
          return value;
        })()}
        {unit ? ` ${unit}` : ""}
      </Value>
    </Wrapper>
  );
};
