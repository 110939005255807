import Logo from "../components/assets/logo.png";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/colors";
import { Stack } from "@mui/material";
import "../../../index.css";

export const ResponsiveAppBar = () => {
  return (
    <AppBar
      position="static"
      sx={{ background: colors.orange, paddingInline: "5px" }}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={Logo} alt="Gyde" width={64} height={64} />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              color: "#233162",
              textDecoration: "none",
              fontFamily: "HemiHead",
            }}
          >
            Gyde
          </Typography>
        </Box>
      </Stack>
    </AppBar>
  );
};
