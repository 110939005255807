import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { colors } from "./theme/colors";

interface TableProps {
  data: any[];
  selectedIds: {};
  setSelectedIds: (c: {}) => void;
  inviteList?: any[];
  noResultsComponent: any;
}

export const SearchTable = ({
  data,
  selectedIds,
  setSelectedIds,
  inviteList,
  noResultsComponent,
}: TableProps) => {
  const [tableData, setTableData] = useState(data ?? []);
  const isAlreadyInvited = {};
  inviteList?.map(p => isAlreadyInvited[p.PersonId] = true);

  useEffect(() => {
    setTableData(data);
  }, [data])

  const requestSearch = value => {
    setTableData(data.filter(row => {
      return `${row.FirstName ?? ""} ${row.LastName ?? ""}`.toLowerCase().includes(value.toLowerCase());
    }));
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (isAlreadyInvited[id]) return;
    if (!selectedIds[id]) selectedIds[id] = true
    else delete selectedIds[id]
    setSelectedIds({ ...selectedIds })
  };

  const isSelected = (id: string) => selectedIds[id] === true;

  return (
    <>
      <Paper
        sx={{
          width: "300px",
          margin: "0 auto",
          border: `1px solid #${colors.lightgrey}`,
          boxShadow: "none",
        }}
      >
        <TextField
          variant="standard"
          placeholder={"Search..."}
          sx={{
            width: "300px",
            input: {
              textIndent: "10px"
            }
          }}
          onChange={e => {
            requestSearch(e.target.value)
          }}
          inputProps={{ type: "search" }}
        />

        <TableContainer sx={{ width: "300px", height: "150px" }}>
          <Table aria-label="simple table">
            <TableBody>
              {tableData.length > 0 ? tableData.map((row, i) => {
                const isItemSelected = isSelected(row.PersonId);
                const name = `${row.FirstName} ${row.LastName || ""}`;
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.PersonId)}
                    role="checkbox"
                    tabIndex={i}
                    key={row.PersonId}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        disabled={isAlreadyInvited[row.PersonId]}
                        color="primary"
                        checked={isItemSelected || isAlreadyInvited[row.PersonId] === true}
                      />
                    </TableCell>
                    <TableCell align="left">{name}</TableCell>
                  </TableRow>
                );
              }) : <TableRow><TableCell>{noResultsComponent}</TableCell></TableRow> ?? ""}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
