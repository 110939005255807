import { gql } from 'urql';

export const IsBusinessSavedQuery = gql`
query isBusinessSaved($input: IsBusinessSavedRequest!){
    isBusinessSaved(input: $input){
        is_saved
    }
}
`
export const SaveBusinessMutation = gql`
mutation ($input: SaveBusinessRequest!) {
    saveBusiness (input: $input) {
        ok
    }
}
`
export const UnsaveBusinessMutation = gql`
mutation ($input: UnsaveBusinessRequest!) {
    unsaveBusiness (input: $input) {
        ok
    }
}
`