import intl from "react-intl-universal";
import { Client, Provider, cacheExchange, fetchExchange } from "urql";
import { authExchange } from "@urql/exchange-auth";
import { getLocale } from "../utils/common";
import { storage } from "../utils/storage";
import { getCurrentLocation } from "../../hooks/location";

const client = new Client({
    url:
        process.env.NODE_ENV === "development"
            ? "http://localhost:8080/query"
            : "https://gyde.live/query",
    exchanges: [
        cacheExchange,
        authExchange(async utils => {
            const pos = await getCurrentLocation()
            return {
                addAuthToOperation(operation) {
                    const token = storage.getItem('accessToken');
                    return utils.appendHeaders(operation, {
                        Authorization: token ? `Bearer ${token}` : "",
                        "X-User-Coordinates": [pos?.latitude, pos?.longitude],
                        "X-Path": `gql/${operation.query?.definitions[0]?.name?.value}`,
                    });
                },
            };
        }),
        fetchExchange,
    ],
});

const locale = getLocale();
const locales = {};
locales[locale] = require(`../assets/locale/en-US.json`);
intl.init({
    currentLocale: locale,
    locales,
    warningHandler: e => {
        if (!e.includes("format message failed")) console.warn(e);
    },
});

document.addEventListener(
    "dragover",
    e => {
        e.preventDefault();
    },
    false
);

export const ApiProvider = ({ children }) => {
    return <Provider value={client}>{children}</Provider>;
};
