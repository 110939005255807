export const colors = {
  orange: "#EF5247",
  blue: "#233162",
  lightblue: "#40518f",
  lightgrey: "#fafafa",
  grey: "#aaaaaa",
  darkgrey: "#555555",
  white: "#FFFFFF",
  transparent: "transparent",
  black: "black",
};
