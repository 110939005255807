import styled from "styled-components";
import { colors } from "../utils/colors";

const Wrapper = styled.button`
  border-radius: 8px;
  color: ${colors.white};
  padding: 0.25em 0.5em;
  text-align: center;
  cursor: pointer;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-size: 18px;
`;

const Primary = styled(Wrapper)`
  background-color: ${colors.orange};
  font-size: 12px;
  width: 75px;
`;
const Secondary = styled.button`
  color: ${colors.blue};
  font-size: 14px;
  font-weight: 500;
`;
const Tertiary = styled(Wrapper)`
  background-color: ${colors.lightgrey};
  border: solid 2px ${colors.blue};
  color: ${colors.blue};
`;

const Quaternary = styled(Wrapper)`
  background-color: ${colors.lightgrey};
  border: solid 2px ${colors.orange};
  color: ${colors.orange};
`;

const LoaderWrapper = styled.div`
  padding: 0.7em 1em;
`;

const Loader = styled.div`
  @keyframes spin {
    100% {
      transform: rotate(1turn);
    }
  }

  --d: 11px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.5px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1.5px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 2.5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 3px;
  animation: spin 0.5s infinite steps(8);
`;

export const PrimaryButton = ({ children, type, loading, onClick }) => {
  return (
    <Primary className="cmp-primary-button" type={type} onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Primary>
  );
};

export const SecondaryButton = ({ children, type, loading, onClick }) => {
  return (
    <Secondary className="cmp-secondary-button" type={type} onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Secondary>
  );
};

export const TertiaryButton = ({ children, type, loading, onClick }) => {
  return (
    <Tertiary className="cmp-tertiary-button" type={type} onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Tertiary>
  );
};

export const QuaternaryButton = ({ children, type, loading, onClick }) => {
  return (
    <Quaternary className="cmp-tertiary-button" type={type} onClick={onClick}>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </Quaternary>
  );
};
