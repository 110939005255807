import styled from "styled-components";
import { useState } from "react";
import { useSafearea } from "./page";

const Wrapper = styled.div`
  text-align: center;
`;

const Image = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  width: 180px;
  height: 140px;
  border-radius: 8px;
  margin: 0.25em;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  display: inline-block;
  cursor: pointer;

  transition: background-image 0.2s;
  filter: brightness(${({ transparent }) => (transparent ? 0.6 : 1)});
`;

const GalleryWrapper = styled.div.attrs(({ $scroll }) => ({
  $scroll: $scroll
}))`
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  position: fixed;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  height: calc(100vh - ${({ offset }) => offset}px);
  top: 0;
  z-index: 1;

  animation: fade 0.2s;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Gallery = styled.div`
  white-space: nowrap;
  overflow: auto;
  width: 100%;
`;

const BigImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
`

const BigImage = styled.img`
  max-width: 90%;
  max-height: 80vh;
  border-radius: 8px;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
`;

export const Carousel = ({ photos }) => {
  const [drill, setDrill] = useState(false);
  const [selected, setSelected] = useState(0);
  const { safearea } = useSafearea();

  return (
    <Wrapper className="cmp-carousel">
      <Gallery>
        {photos.map((src, i) => {
          return (
            <Image
              key={i}
              src={src}
              onClick={() => {
                setDrill(true);
                setSelected(i);
              }}
            />
          );
        })}
      </Gallery>
      {drill ? (
        <GalleryWrapper
          onClick={(e) => { setDrill(false) }}
          offset={safearea.bottom + safearea.top}
        >
          {
            photos.map((photo, i) => {
              return <BigImageWrapper key={i}>
                <BigImage
                  src={photo}
                  offset={safearea.bottom + safearea.top}
                />
              </BigImageWrapper>
            })
          }
        </GalleryWrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};
