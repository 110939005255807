import axios from "axios";
import { Geolocation } from '@capacitor/geolocation';
import { storage } from "../src-gql/utils/storage";
import { Capacitor } from "@capacitor/core";

export const getCurrentLocation = (maxAge = 0, retry = true) => {
  return new Promise((resolve, reject) => {
    if (Capacitor.isNativePlatform()) {
      Geolocation.getCurrentPosition().then((pos) => {
        storage.setItem('coordinates', pos)
        axios.defaults.headers.common["X-User-Coordinates"] = [
          pos.coords.latitude,
          pos.coords.longitude,
        ];
        resolve(pos.coords);
      }).catch((err) => {
        if (retry) getCurrentLocation(maxAge, retry)
        else reject(err)
      })
    } else {
      if (!navigator.geolocation) {
        const err = new Error("geolocation not available");
        console.error(err);
        return;
      }
      navigator.geolocation.getCurrentPosition(
        pos => {
          console.log('coordinates fetched')
          axios.defaults.headers.common["X-User-Coordinates"] = [
            pos.coords.latitude,
            pos.coords.longitude,
          ];
          resolve(pos.coords);
        },
        err => {
          console.error(err);
          if (retry) getCurrentLocation(maxAge, false);
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: maxAge,
        }
      );
    }
  })
};
