import { colors } from "./theme/colors";
import { importContacts } from "../hooks/people";
import { Contacts } from "@capacitor-community/contacts";
import { Capacitor } from '@capacitor/core';
import axios from "axios";
import { SERVER_URL } from "../utils";
import { useAppContext } from "../AppContext";

export const EmptyContactsList = ({ eventId, setContactList }) => {
    const { user } = useAppContext();
    return <>
        <div style={{
            margin: "5px auto",
            width: "fit-content",
        }}>No contacts found</div>
        {
            Capacitor.isNativePlatform() ? <>
                <div style={{
                    cursor: "pointer",
                    background: colors.blue,
                    color: colors.white,
                    width: "fit-content",
                    margin: "5px auto",
                    padding: "5px 10px",
                    borderRadius: "5px"
                }} onClick={() => {
                    RetrieveContacts().then(() => {
                        axios
                            .post(
                                `${SERVER_URL}/api/event/list-contacts`,
                                JSON.stringify({
                                    event_id: eventId,
                                })
                            )
                            .then(function (res) {
                                setContactList((res?.data ?? []).filter((c) => {
                                    return c.PersonId !== user.person_id
                                }))
                            })
                    }).catch(err => {
                        console.error(err);
                    });
                }}>
                    Import Contacts
                </div>
            </> : ""
        }
    </>
}

export const RetrieveContacts = () => {
    return new Promise((resolve, reject) => {
        if (!Capacitor.isNativePlatform()) {
            resolve();
            return;
        }
        Contacts.getContacts({
            projection: {
                name: true,
                phones: true,
                emails: true,
            },
        })
            .then(resp => {
                const contactsList = [];
                resp.contacts.forEach((c, i) => {
                    if (!c.name) return;
                    const phones = c.phones || [];
                    const emails = c.emails || [];
                    const contact = {
                        first_name: c.name.given,
                        last_name: c.name.family,
                        phone: "",
                        email: "",
                        pfp_url: c.image?.base64String,
                    };
                    if (phones.length === 0 && emails.length === 0) return;
                    if (phones.length > 0) contact.phone = phones[0].number;
                    if (emails.length > 0) contact.email = emails[0].address;
                    if (contact.first_name === "" && contact.last_name === "") return;
                    contactsList.push(contact);
                });
                importContacts(contactsList).then((r) => {
                    resolve()
                }).catch(err => {
                    console.error("failed to import contacts", err);
                    reject(err)
                });
            })
            .catch(e => {
                console.error(e);
                reject(e);
            });
    })
}