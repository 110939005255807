import React from "react";

import "../index.css";
import "./Spinner.css";
import RingImg from "../assets/ring.png";
import DogRunningAnim from "../assets/dog-running.gif";
import { useAppContext } from "../AppContext";
import { colors } from "./theme/colors";

export const Spinner = ({ text, background }) => {
  return (
    <div
      id="spinner"
      className="cmp-spinner"
      style={{
        position: "fixed",
        width: "100%",
        height: "100vh",
        zIndex: 100,
        transitionDuration: "0.4s",
        transitionProperty: "opacity",
        backgroundColor:
          background === "bg-solid"
            ? colors.orange
            : "rgba(255, 255, 255, 0.5)",
      }}
    >
      <img
        id="dog-running"
        src={DogRunningAnim}
        alt="dog-runing-animation"
      ></img>
      <img id="ring" src={RingImg} alt="ring-under-the-dog"></img>
      <div id="spinner-text">{text}</div>
    </div>
  );
};
