export const mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const handleLinkClick = (name, address1, address2) => {
  const newMapUrl = `https://www.google.com/maps/search/${name}${address1}${address2}`;
  window.open(newMapUrl);
};

// Testing with local browser
export const SERVER_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://gyde.live";
// export const SERVER_URL = "http://localhost:8080";

export const timeConverter = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  var abbrevMonths = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = start.getDate();
  const year = start.getFullYear();
  const hour = start.getHours();
  const min = start.getMinutes();
  const abbrevCalMonth = abbrevMonths[start.getMonth()];

  const endHour = end.getHours();
  const endMin = end.getMinutes();

  // calculate
  var startValue, endValue;
  const todayDay = new Date();
  const todayDate = todayDay.getDate();
  const todayYear = todayDay.getFullYear();
  const todayMonth = abbrevMonths[todayDay.getMonth()];
  const today = "Today";

  if (hour > 0 && hour <= 12) {
    startValue = "" + hour;
  } else if (hour > 12) {
    startValue = "" + (hour - 12);
  } else if (hour === 0) {
    startValue = "12";
  }

  if (endHour > 0 && endHour <= 12) {
    endValue = "" + endHour;
  } else if (endHour > 12) {
    endValue = "" + (endHour - 12);
  } else if (endHour === 0) {
    endValue = "12";
  }

  startValue += min < 10 ? ":0" + min : ":" + min; // get minutes
  startValue += hour >= 12 ? " P.M." : " A.M."; // get AM/PM

  endValue += endMin < 10 ? ":0" + endMin : ":" + endMin; // get minutes
  endValue += endHour >= 12 ? " P.M." : " A.M."; // get AM/PM

  const startingTime =
    abbrevCalMonth + " " + day + ", " + year + " " + startValue;
  const abbrevStartTime = abbrevCalMonth + " " + day + ", " + startValue;
  const abbrevEndTime = abbrevCalMonth + " " + day + ", " + endValue;

  if (
    todayDate === day &&
    todayYear === year &&
    todayMonth === abbrevCalMonth
  ) {
    return endTime
      ? today + " " + startValue + " - " + endValue
      : today + " " + startValue;
  } else if (endTime) {
    return abbrevStartTime + " - " + abbrevEndTime;
  } else return startingTime;
};

export const timeConverterCombined = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  let dateText = start.toLocaleDateString([], {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  let timeText = ` ${start.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
  if (!endTime) return [dateText, timeText];

  if (
    start.getMonth() !== end.getMonth() ||
    start.getDate() !== end.getDate()
  ) {
    dateText += ` - ${end.getDate()}`;
  }
  if (
    start.getHours() !== end.getHours() ||
    start.getMinutes() !== end.getMinutes()
  ) {
    timeText += ` - ${end.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
  return [dateText, timeText];
};

export const daysFromDate = (dt, days) => {
  const tmp = new Date(dt);
  tmp.setDate(tmp.getDate() + days);
  return tmp;
};

export const eventUrlFilter = url => {
  if (
    url.includes("livenation") ||
    url.includes("ticketmaster") ||
    url.includes("ticket") ||
    url.includes("tickets")
  ) {
    return <span>Tickets</span>;
  } else return <span>Website</span>;
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = new RegExp(
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,36}$/
);