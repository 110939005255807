import styled from "styled-components";
import { colors } from "../utils/colors";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Capacitor } from "@capacitor/core";
import { createContext, useContext, useState, useMemo, useEffect } from "react";

import { TopBar } from "./topbar";
import { Navbar } from "./navbar";
import { Loader } from "./loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  font-family: OpenSans;
  color: ${colors.blue};
  overflow: hidden;
  font-size: 1rem;
`;

const Content = styled.div.attrs(({ $top, $bottom }) => ({
  $top,
  $bottom,
}))`
  position: relative;
  width: 100%;
  height: calc(100vh - 100px - 8px - ${({ $bottom }) => $bottom}px);
`;

export const SafeAreaCtx = createContext({});
export const useSafearea = () => useContext(SafeAreaCtx);

export const Page = props => {
  const { topbarCfg, navbarCfg, children, loadingText } = props;
  const [safearea, setSafearea] = useState({ top: 0, bottom: 0 });

  useEffect(() => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === "ios") {
        setSafearea(insets);
      }
    });
  }, []);

  if (topbarCfg)
    topbarCfg.visible = !loadingText;

  return (
    <SafeAreaCtx.Provider value={useMemo(() => ({ safearea }), [safearea])}>
      <Wrapper className="cmp-page">
        {loadingText ? <Loader loadingText={loadingText} /> : ""}
        <TopBar {...topbarCfg} />
        <Content $top={safearea.top} $bottom={safearea.bottom}>
          {children}
        </Content>
        {!loadingText && <Navbar {...navbarCfg} />}
      </Wrapper>
    </SafeAreaCtx.Provider>
  );
};
