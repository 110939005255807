import styled from "styled-components";
import { colors } from "../utils/colors";
import { useState } from "react";

const Wrapper = styled.div`
  font-size: 15px;
  & > * {
    display: inline-block;
  }
  display: inline-flex;
  align-items: baseline;
`;

const ToggleWrapper = styled.div`
  position: relative;
  left: 6px;
  width: 30px;
  height: 12px;
  border-radius: 24px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: ${({ on }) => (on ? colors.blue : colors.grey)};
`;

const ToggleKnob = styled.div`
  border-radius: 30px;
  width: 12px;
  height: 12px;
  margin-left: 3px;
  position: absolute;
  background-color: ${colors.white};
  transition: left 0.3s;
  left: ${({ on }) => (on ? 15 : 0)}px;
`;

export const Toggle = ({ label, defaultValue, onSwitch }) => {
  const [v, setV] = useState(defaultValue);
  return (
    <Wrapper className="cmp-toggle">
      {label ? <div style={{ fontSize: "1rem" }}>{label}</div> : ""}
      <ToggleWrapper
        on={v ? "true" : ""}
        onClick={e => {
          if (onSwitch) onSwitch(e, !v);
          setV(!v);
        }}
      >
        <ToggleKnob on={v ? "true" : ""} />
      </ToggleWrapper>
    </Wrapper>
  );
};
